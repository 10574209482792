$(document).ready(function () {
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleQuicklink();
    convertToTap();
    searchComponent();
    calculateViewportUnits();
    quizModal();

    $('body').addClass("loading");

    $(window).resize(function() {
        handleQuicklink();
    });
});

$(window).on("load", function() {

    handleFooter();
    $(window).trigger('resize');
});

function searchComponent() {
    var searchComponent = $(".search-component");
    var searchButton = $("#search-btn");

    if(searchComponent != "" && searchButton != ""){
        $(searchButton).on("click", function () {
            $("body").toggleClass("search-component--open");
        })
    }

    $(searchComponent).on("click", function (e) {
        if($(e.target).is('.search-component__input')){
            e.preventDefault();
            return;
        }
        $("body").removeClass("search-component--open");
    })
}

function handleQuicklink() {
    var element = $('.quicklink');
    var tileWidth = element.outerWidth( true );
    var tileHeight = tileWidth;

    element.height(tileHeight)
}

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {
    $('.owl-carousel').owlCarousel({
        items:1
    })
}

function gallery() {
    $(".showLargeImage").each(function(index,item) {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });
    });

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {
    progressiveImageLoader();
    progressiveBackgroundImage();
};

function progressiveBackgroundImage() {

    var placeholder = $(".bg-progressive");

    placeholder.each(function(index,element) {

        var imgUrlSmall = $(element).data('small');
        var imgUrlLarge = $(element).data('large');

        var imageWrapperSmall = $('<div class="background-image-small"></div>');
        var imageWrapperLarge = $('<div class="background-image-large"></div>');

        $(imageWrapperSmall).css("background-image","url('" +imgUrlSmall+ "')");
        $(imageWrapperLarge).css("background-image","url('" +imgUrlLarge+ "')");

        $(imageWrapperSmall).appendTo(element);
        $(imageWrapperLarge).appendTo(element);

        var img = new Image();
        img.src = imgUrlLarge;

        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };
    });
}

function progressiveImageLoader() {
    var placeholder = $("figure.figure-progressive");

    placeholder.each(function(index,element) {

        //load small image
        var imageWrapperSmall = $('<img class="image-small">');
        imageWrapperSmall.attr('src', $(element).attr('data-small'));

        // //load large image
        var imageWrapperLarge = $('<img class="image-large">');
        imageWrapperLarge.attr('src', $(element).attr('data-large')).attr('srcset', $(element).attr('data-srcset')).attr('alt', $(element).attr('data-alt'));;

        imageWrapperSmall.appendTo(element);
        imageWrapperLarge.appendTo(element);

        var img = new Image();
        img.src = $(element).attr('data-large');

        //create image in DOM
        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };

    });
}

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function convertToTap() {
    $(".tabs").each(function (index, element) {

        var tabsContainer = $(element).find(".tab-container");
        var tabsComponent = tabsContainer.find(".tab-component");
        var accordionElement = $('<div class="accordion" id="accordionComponent"></div>');

        $(accordionElement).appendTo(tabsContainer);

        var $newAccordionItemTitle = new Array();
        var $newAccordionItemText = new Array();
        //
        tabsComponent.find(".nav-tabs .nav-item").each(function (index, element) {
            $newAccordionItemTitle.push(element.text);
            $(accordionElement).append('<div class="card"><div class="card-header" id="heading'+ index +'" data-toggle="collapse" data-target="#collapse'+ index +'" aria-controls="collapse'+ index +'">' + $newAccordionItemTitle[index] +'<span class="custom-arrow"></span></div></div>');

        });

        tabsComponent.find(".tab-content .tab-pane").each(function (index, element) {
            $newAccordionItemText.push($(this).text());
            accordionElement.find('.card:nth-child(' + (index + 1) +')').append('<div class="collapse" id="collapse'+ index +'" data-parent="#accordionComponent"><div class="card-body">'+ $newAccordionItemText[index] +'</div></div>');
            accordionElement.find('.card:first-child .card-header').attr("aria-expanded","true");
            accordionElement.find('.card:first-child .collapse').addClass("show");
        });

        $(window).resize(function() {
            var screenSize = $( window ).outerWidth();

            if (screenSize < 768) {
                $(accordionElement).appendTo(tabsContainer);
                $(tabsComponent).detach();
            }
            else {
                $(tabsContainer).find(accordionElement).detach();
                $(tabsComponent).appendTo(tabsContainer);
            }
        });

    });
}

function calculateViewportUnits() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    var vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', vh + "px");

    // We listen to the resize event
    window.addEventListener('resize', function() {
        // We execute the same script as before
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + "px");
    });
}

function quizModal() {
    console.log("check");
    $('#quizModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget)
        var quizTitle = button.data('title')
        var quizDescription = button.data('description')
        var quizLink = button.data('link')
        var quizNewTab = button.data('tab')

        var modal = $(this)
        modal.find('.modal-title').text(quizTitle)
        modal.find('.modal-body-content').text(quizDescription)
        if(quizLink) {
            modal.find('.modal-link').attr("href", quizLink); modal.find('.modal-link').css("display", "block"); modal.find('.modal-footer').css("justify-content", "space-between");
        } else {
            modal.find('.modal-link').css("display", "none"); modal.find('.modal-footer').css("justify-content", "end");
        }
        if(quizNewTab === 1) { modal.find('.modal-link').attr("target", "_blank") }
        if(quizNewTab === 0) { modal.find('.modal-link').attr("target", "_self") }
    })
}